import {createAsyncThunk}       from "@reduxjs/toolkit";
import type {ITodo}             from "./models";
import type {DocumentReference} from "firebase/firestore";

const COLLECTION = "todos";

export const createTodoAction = createAsyncThunk(
    "todos/create",
    async ({id, ...todo}: ITodo) => {
      const {getFirestore, collection, addDoc} = await import("firebase/firestore");
      const created = await addDoc(collection(getFirestore(), COLLECTION), todo);
      return {id: created.id, ...todo};
    },
);
export const toggleTodoAction = createAsyncThunk(
    "todos/toggle",
    async (todo: ITodo) => {
      todo.done = !todo.done;
      const {getFirestore, doc, updateDoc} = await import("firebase/firestore");
      await updateDoc(doc(getFirestore(), COLLECTION, todo.id!) as DocumentReference<ITodo>, {done: todo.done});
      return todo;
    },
);

export const deleteTodoAction = createAsyncThunk(
    "todos/delete",
    async (todoID: string) => {
      const {getFirestore, collection, doc, deleteDoc} = await import("firebase/firestore");
      await deleteDoc(doc(collection(getFirestore(), COLLECTION)));
      return todoID;
    },
);

import {todoAdapter}                from "./todoAdapter";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as actions                 from "./todoActions";
import type {ITodo, ITodoList}      from "./models";

export interface TodosFeature {
  status: "idle" | "loading" | "failed";
}

const initialState = todoAdapter.getInitialState<TodosFeature>({status: "idle"});

export const todoSlice = createSlice({
  name: "todo",
  initialState,
  reducers: {
    todosChange: (state, action: PayloadAction<ITodo[]>) => {
      todoAdapter.upsertMany(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(actions.createTodoAction.pending, state => {
      state.status = "loading";
    });
    builder.addCase(actions.createTodoAction.fulfilled, (state, action) => {
      state.status = "idle";
      todoAdapter.addOne(state, action.payload);
    });
    builder.addCase(actions.createTodoAction.rejected, state => {
      state.status = "failed";
      // TODO Add to store & add todos to offline create queue
    });

    builder.addCase(actions.toggleTodoAction.pending, state => {
      state.status = "loading";
    });
    builder.addCase(actions.toggleTodoAction.fulfilled, (state, action) => {
      state.status = "idle";
      todoAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(actions.toggleTodoAction.rejected, state => {
      state.status = "failed";
      // TODO Update in store & add todos to offline update queue
    });

    builder.addCase(actions.deleteTodoAction.pending, state => {
      state.status = "loading";
    });
    builder.addCase(actions.deleteTodoAction.fulfilled, (state, action) => {
      state.status = "idle";
      todoAdapter.removeOne(state, action.payload);
    });
    builder.addCase(actions.deleteTodoAction.rejected, state => {
      state.status = "failed";
      // TODO Delete from store & add todos to offline delete queue
    });
  },
});

export type TodoRootState = ReturnType<typeof todoSlice.reducer>;

export const {todosChange} = todoSlice.actions;

export const selectTodos = (state: TodoRootState) => todoAdapter.getSelectors().selectAll(state);

export const selectLists = (state: TodoRootState) => selectTodos(state).reduce((lists, todo) => lists.add(todo.list), new Set());

export const selectUserTodos = (state: TodoRootState, userId: string) => todoAdapter.getSelectors().selectAll(state).filter(todo => todo.user === userId);

export const selectTodoListToDos = (state: TodoRootState, list: ITodoList) => selectUserTodos(state, list.owner).filter(todo => todo.list === list.id);

export default todoSlice.reducer;

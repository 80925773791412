import {createEntityAdapter} from "@reduxjs/toolkit";
import type {ITodo}          from "./models";

export const todoSelectId = (model: ITodo) => model.id!;
export const todoSortComparer = (a: ITodo, b: ITodo) => {
  if (a.done && !b.done) return 1;
  if (!a.done && b.done) return -1;

  if (a.order < b.order) return -1;
  return Number(a.order > b.order);
};
export const todoAdapter = createEntityAdapter<ITodo>({
  selectId: todoSelectId,
  sortComparer: todoSortComparer,
});

export interface IUserProfile {
  id?: string;
  displayName: string;
  creationDate: Date;
}

export type IUserProfileSerialized = Omit<IUserProfile, "creationDate"> & {
  creationDate: number;
};

export const serializeUserProfile = (userProfile: IUserProfile): IUserProfileSerialized => ({
  ...userProfile,
  creationDate: userProfile.creationDate.getTime(),
});
export const unserializeUserProfile = (userProfileSerialized: IUserProfileSerialized): IUserProfile => ({
  ...userProfileSerialized,
  creationDate: new Date(userProfileSerialized.creationDate),
});

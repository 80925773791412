import React                                               from "react";
import {BrowserRouter, Outlet, Route, Routes, useLocation} from "react-router-dom";
import {useAuth}                                           from "reactfire";
import {Navigate}                                          from "react-router";
import Stack                                               from "@mui/material/Stack/Stack";
import CircularProgress                                    from "@mui/material/CircularProgress/CircularProgress";
import Typography                                          from "@mui/material/Typography/Typography";
import {useAppSelector}                                    from "./app/hooks";
import {selectAuth}                                        from "./features/auth/authSlice";
// Routing
import {PRIVATE_ROUTES, PUBLIC_ROUTES, AUTH_ROUTES, ALL_ROUTES, PUBLIC_ROUTE, PRIVATE_ROUTE} from "./app/routes";

const TodoPage = React.lazy(() => import("./pages/todo"));
const LoginPage = React.lazy(() => import("./pages/login"));
const RegisterPage = React.lazy(() => import("./pages/register"));
const HomePage = React.lazy(() => import("./pages/home"));

export const NoRoute = () => {
  const location = useLocation();
  console.debug(location);
  return <div>404 - PAGE NOT FOUND</div>;
};

const pageComponentFromRoute = (route: typeof ALL_ROUTES[number]) => {
  switch (route) {
    case "/":
      return <HomePage />;
    case "/todos":
    case "/todos/:list":
      return <TodoPage />;
    case "/login":
      return <LoginPage />;
    case "/register":
      return <RegisterPage />;
    case "/reset-password":
    default:
      return <NoRoute />;
  }
};

export const PrivateRoute = () => {
  const {currentUser} = useAuth();
  const location = useLocation();
  console.debug(location);
  if (!currentUser) {
    return <Navigate to={"login" as typeof AUTH_ROUTES[number]} state={{from: location}} replace />;
  }
  return <Outlet />;
};
export const AuthRoute = () => {
  const userProfile = useAppSelector(state => selectAuth(state.auth));
  const location = useLocation();
  if (userProfile !== undefined) {
    return <Navigate to={"/" as (PUBLIC_ROUTE | PRIVATE_ROUTE)} state={{from: location}} replace />;
  }
  return <Outlet />;
};
const LogOut = () => {
  const auth = useAuth();
  React.useEffect(() => {
    if (auth.currentUser) auth.signOut();
  }, [auth]);

  return null;
};

export const LoadingScreen = () => {
  return (
      <Stack
          direction="row" justifyContent="center" alignItems="center" gap={5} sx={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        inset: 0,
        zIndex: 1000,
        backgroundColor: "white"
      }}
      >
        <CircularProgress size={64} />
        <Typography component="p" variant="h4">
          Chargement de l'application en cours…
        </Typography>
      </Stack>
  );
};

export const Router = () => {
  return (
      <React.Suspense fallback="Chargement de la page">
        <BrowserRouter>
          <Routes>

            {/*AUTH ROUTES*/}
            <Route path="/" element={<AuthRoute />}>
              {AUTH_ROUTES.map(route => (
                  <Route key={route} path={(route as string).replace("/", "")} element={pageComponentFromRoute(route)} />
              ))}
            </Route>

            {/*PUBLIC ROUTES*/}
            <Route path="/" element={<Outlet />}>
              {PUBLIC_ROUTES.map(route => (
                  route === ("/" as typeof PUBLIC_ROUTES[number])
                      ? <Route key={route} index element={pageComponentFromRoute(route)} />
                      : <Route key={route} path={(route as string).replace("/", "")} element={pageComponentFromRoute(route)} />
              ))}
            </Route>

            {/*PRIVATE ROUTES*/}
            <Route path="/" element={<PrivateRoute />}>
              {PRIVATE_ROUTES.map(route => (
                  <Route key={route} path={(route as string).replace("/", "")} element={pageComponentFromRoute(route)} />
              ))}
              <Route path="logout" element={<LogOut />} />
            </Route>

            {/*404*/}
            <Route path="*" element={<NoRoute />} />

          </Routes>
        </BrowserRouter>
      </React.Suspense>
  );
};

import {createAsyncThunk}       from "@reduxjs/toolkit";
import type {ITodoList}         from "../todos/models";
import type {DocumentReference} from "firebase/firestore";

const COLLECTION = "todo-list";

export const CreateTodoListAction = createAsyncThunk<ITodoList, ITodoList>(
    "todo-list/create",
    async ({id, ...list}) => {
      const {getFirestore, collection, addDoc} = await import("firebase/firestore");
      const created = await addDoc(collection(getFirestore(), COLLECTION), list);
      return {id: created.id, ...list};
    }
);
export const RenameTodoListAction = createAsyncThunk<ITodoList, ITodoList>(
    "todo-list/rename",
    async (list) => {
      const {getFirestore, doc, updateDoc} = await import("firebase/firestore");
      await updateDoc(doc(getFirestore(), COLLECTION, list.id!) as DocumentReference<ITodoList>, {name: list.name});
      return list;
    }
);
export const ChangeTodoListOrderAction = createAsyncThunk<ITodoList, ITodoList>(
    "todo-list/reorder",
    async (list) => {
      // TODO Update in Firestore (given AND others list)
      const {getFirestore, doc, updateDoc} = await import("firebase/firestore");
      await updateDoc(doc(getFirestore(), COLLECTION, list.id!) as DocumentReference<ITodoList>, {name: list.name});
      return list;
    }
);
export const DeleteTodoListAction = createAsyncThunk(
    "todo-list/delete",
    async (list: ITodoList) => {
      const {getFirestore, doc, deleteDoc} = await import("firebase/firestore");
      await deleteDoc(doc(getFirestore(), COLLECTION, list.id!));
      return list.id;
    }
);

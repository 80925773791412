import {createSlice, PayloadAction}                     from "@reduxjs/toolkit";
import {IUserProfileSerialized, unserializeUserProfile} from "./models/IUserProfile";
import * as actions                                     from "./authActions";

export type EmailPasswordCredential = {
  email: string;
  password: string;
};

type AuthState = {
  status: 'idle' | 'loading' | 'failed';
  user?: IUserProfileSerialized;
};
const initialState: AuthState = { status: 'idle' };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userChange: (state, action: PayloadAction<IUserProfileSerialized | undefined>) => {
      state.user = action.payload;
    },
  },
  extraReducers: builder => {
    // Register
    builder.addCase(actions.AuthRegisterAction.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(actions.AuthRegisterAction.fulfilled, (state, action) => {
      state.status = 'idle';
      state.user = action.payload;
    });
    builder.addCase(actions.AuthRegisterAction.rejected, (state, action) => {
      state.status = 'failed';
      state.user = undefined;
      console.debug("Register failed :", action.error);
    });

    // Login
    builder.addCase(actions.AuthLoginAction.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(actions.AuthLoginAction.fulfilled, (state, action) => {
      state.status = 'idle';
      state.user = action.payload;
    });
    builder.addCase(actions.AuthLoginAction.rejected, (state, action) => {
      state.status = 'failed';
      state.user = undefined;
      console.debug("Login failed :", action.error);
    });
  },
});

export type AuthRootState = ReturnType<typeof authSlice.reducer>;

export const {userChange} = authSlice.actions;

export const selectAuthStatus = (state: AuthRootState) => state.status;
export const selectAuth = (state: AuthRootState) => state.user ? unserializeUserProfile(state.user) : undefined;

export default authSlice.reducer;

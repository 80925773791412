import React                                                            from "react";
import {Router}                                                         from "./Router";
import {connectFirestoreEmulator, initializeFirestore}                  from "firebase/firestore";
import {connectAuthEmulator, initializeAuth}                            from "firebase/auth";
import {FirestoreProvider, AuthProvider, useInitFirestore, useInitAuth} from "reactfire";
import createTheme                                                      from "@mui/material/styles/createTheme";
import ThemeProvider                                                    from "@mui/material/styles/ThemeProvider";
import CssBaseline                                                      from "@mui/material/CssBaseline/CssBaseline";
import Typography                                                       from "@mui/material/Typography/Typography";
import {store}                                                          from "./app/store";
import {Provider}                                                       from "react-redux";

const theme = createTheme();

function App() {
  const {status: authStatus, data: authInstance} = useInitAuth(async (firebaseApp) => {
    const auth = initializeAuth(firebaseApp);
    if (process.env.NODE_ENV === "development") {
      console.debug("Connecting to auth emulator");
      connectAuthEmulator(authInstance, "http://localhost:9099");
    }
    return auth;
  });
  const {status: firestoreStatus, data: firestoreInstance} = useInitFirestore(async (firebaseApp) => {
    const db = initializeFirestore(firebaseApp, {ignoreUndefinedProperties: true});
    if (process.env.NODE_ENV === "development") {
      console.debug("Connecting to firestore emulator");
      connectFirestoreEmulator(db, "localhost", 8080);
    }
    return db;
  });

  if (authStatus === "loading" || firestoreStatus === "loading") {
    return (
        <Typography>
          Chargement de l'application…
        </Typography>
    );
  }

  return (
      <Provider store={store}>
        <AuthProvider sdk={authInstance}>
          <FirestoreProvider sdk={firestoreInstance}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router />
            </ThemeProvider>
          </FirestoreProvider>
        </AuthProvider>
      </Provider>
  );
}

export default App;

import {createAsyncThunk}                                                         from "@reduxjs/toolkit";
import {EmailPasswordCredential}                                                  from "./authSlice";
import {IUserProfile, serializeUserProfile}                                       from "./models/IUserProfile";
import type {CollectionReference, DocumentSnapshot, DocumentReference, Timestamp} from "firebase/firestore";

export const AuthRegisterAction = createAsyncThunk(
    "auth/register",
    async (credential: EmailPasswordCredential) => {
      const {getAuth, createUserWithEmailAndPassword} = await import("firebase/auth");
      const {user} = await createUserWithEmailAndPassword(getAuth(), credential.email, credential.password);

      const {getFirestore, doc, setDoc} = await import("firebase/firestore");
      const userProfileRef = doc(getFirestore(), "userProfile", user.uid) as DocumentReference<IUserProfile>;
      const userProfile: IUserProfile = {
        id: user.uid,
        displayName: user.displayName || user.email || user.uid,
        creationDate: new Date(),
      };
      await setDoc(userProfileRef, {
        displayName: userProfile.displayName,
        creationDate: userProfile.creationDate,
      });

      return serializeUserProfile(userProfile);
    },
);

export const AuthLoginAction = createAsyncThunk(
    "auth/login",
    async (credential: EmailPasswordCredential) => {
      const {getAuth, signInWithEmailAndPassword} = await import("firebase/auth");
      const {user} = await signInWithEmailAndPassword(getAuth(), credential.email, credential.password);

      const {getFirestore, collection, doc, getDoc} = await import("firebase/firestore");
      const userProfileRef = doc(collection(getFirestore(), "userProfile") as CollectionReference<IUserProfile>, user.uid);
      const userProfileSnapshot: DocumentSnapshot<IUserProfile> = await getDoc(userProfileRef);
      const data = userProfileSnapshot.data()!;
      const userProfile: IUserProfile = {
        id: userProfileSnapshot.id,
        ...data,
        creationDate: (data.creationDate as unknown as Timestamp).toDate(),
      };

      return serializeUserProfile(userProfile);
    },
);

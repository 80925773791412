import {todoListAdapter, todoListSelectId} from "./todoListAdapter";
import {createSlice, PayloadAction}        from "@reduxjs/toolkit";
import type {ITodoList}                    from "../todos/models";
import * as actions                        from "./todoListActions";

export interface TodoListState {
  status: "idle" | "loading" | "failed";
}

const initialState = todoListAdapter.getInitialState<TodoListState>({status: "idle"});

export const todoListSlice = createSlice({
  name: "todo-list",
  initialState,
  reducers: {
    listChange: (state, action: PayloadAction<ITodoList[]>) => {
      todoListAdapter.upsertMany(state, action);
    },
  },
  extraReducers: builder => {
    builder.addCase(actions.CreateTodoListAction.pending, state => {
      state.status = "loading";
    });
    builder.addCase(actions.CreateTodoListAction.fulfilled, (state, action) => {
      state.status = "idle";
      todoListAdapter.addOne(state, action.payload);
    });
    builder.addCase(actions.CreateTodoListAction.rejected, state => {
      state.status = "failed";
    });

    builder.addCase(actions.RenameTodoListAction.pending, state => {
      state.status = "loading";
    });
    builder.addCase(actions.RenameTodoListAction.fulfilled, (state, action) => {
      state.status = "idle";
      todoListAdapter.updateOne(state, {id: action.payload.id, changes: {name: action.payload.name}});
    });
    builder.addCase(actions.RenameTodoListAction.rejected, state => {
      state.status = "failed";
    });

    builder.addCase(actions.ChangeTodoListOrderAction.pending, state => {
      state.status = "loading";
    });
    builder.addCase(actions.ChangeTodoListOrderAction.fulfilled, (state, action) => {
      state.status = "idle";

      const {id, order} = action.payload;
      const listsArrayWithoutListToReorder = selectUserTodoLists(state, action.payload.owner).filter(list => list.id !== id);
      const removed = listsArrayWithoutListToReorder.splice(order);
      const orderedListArray = [...listsArrayWithoutListToReorder, action.payload, ...removed.map(list => ({
        ...list,
        order: list.order + 1
      }))];

      todoListAdapter.updateMany(state, orderedListArray.map(list => ({id: list.id, changes: {order: list.order}})));
    });
    builder.addCase(actions.ChangeTodoListOrderAction.rejected, state => {
      state.status = "failed";
    });

    builder.addCase(actions.DeleteTodoListAction.pending, state => {
      state.status = "loading";
    });
    builder.addCase(actions.DeleteTodoListAction.fulfilled, (state, action) => {
      state.status = "idle";
      todoListAdapter.removeOne(state, action.payload);
    });
    builder.addCase(actions.DeleteTodoListAction.rejected, state => {
      state.status = "failed";
    });
  },
});

export type TodoListRootState = ReturnType<typeof todoListSlice.reducer>;

export const {listChange} = todoListSlice.actions;

export const selectTodoLists = (state: TodoListRootState) => todoListAdapter.getSelectors().selectAll(state);

export const selectTodoListByID = (state: TodoListRootState, id: ReturnType<typeof todoListSelectId>) => todoListAdapter.getSelectors().selectById(state, id);

export const selectUserTodoLists = (state: TodoListRootState, user: string) => selectTodoLists(state).filter(list => list.owner === user);

export default todoListSlice.reducer;

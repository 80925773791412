import {configureStore} from "@reduxjs/toolkit";
import authReducer      from "../features/auth/authSlice";
import todoReducer      from "../features/todos/todoSlice";
import todoListReducer      from "../features/todos-lists/todoListSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    todo: todoReducer,
    todoList: todoListReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
